import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "react-credit-cards/es/styles-compiled.css";
import { ToastContainer } from 'react-toastify';

import LoadingSpinner from './service/loaders/LoadingSpinner';

const TermConditions = lazy(() => import('./page/TermConditions/TermConditions'));
const MyTicket = lazy(() => import('./page/MyTickets/MyTicket'));
const Login = lazy(() => import('./page/Login/Login'));
const Home = lazy(() => import('./page/Home/Home'));
const Register = lazy(() => import('./page/Register/Register'));
// const EventDetail = lazy(() => import('./page/EventDetail/EventDetail'));
// const EventDetail = lazy(() => import('./friends-family/pages/EventDetails'));
const EventDetail = lazy(() => import('./friends-family/pages/EventDetailWithoutValidation'));
const Payment = lazy(() => import('./page/Payment/Payment'));
const Custom404 = lazy(() => import('./page/404/404'));
const Calendar = lazy(() => import('./page/Calendar/Calendar'));
const Announce = lazy(() => import('./page/Announce/Announce'));

interface Props {
  children?: React.ReactNode;
}
const Render = (props: Props) => {
  return (
    <Suspense fallback={<LoadingSpinner></LoadingSpinner>}>
      {props.children}
    </Suspense>
  )
}
const App: React.FC = () => {

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Render children={<Announce />} />} />
        <Route path="home" element={<Render children={<Home />} />} /> */}
        <Route path="/" element={<Render children={<Home />} />} />
        <Route path="home" element={<Render children={<Home />} />} />
        <Route path="login" element={<Render children={<Login />} />} />
        <Route path="register" element={<Render children={<Register />} />} />
        <Route path="*" element={<Render children={<Custom404 />} />} />
        <Route path="/event/eventDetail/:id" element={<Render children={<EventDetail />} />} />
        {/* <Route path="/event/:id/payment" element={<Render children={<Payment />} />} /> */}
        <Route path="/my-tickets" element={<Render children={<MyTicket />} />} />
        <Route path="/term-conditions" element={<Render children={<TermConditions />} />} />
        <Route path="/calendar" element={<Render children={<Calendar />} />} />
      </Routes>
    </BrowserRouter>
  )
}
export default App;
